<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      {{ searchTypeName }} de código SAT
    </h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        @click="changeAdvancedSearch()"
        class="special-margin"
        v-if="searchType === 1"
      >
        Cambiar a búsqueda avanzada
      </b-button>

      <b-button
        type="is-primary"
        @click="changeSimpleSearch()"
        class="special-margin"
        v-if="searchType === 0"
      >
        Cambiar a búsqueda simple
      </b-button>
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <div v-if="searchType === 1">
      <b-field grouped class="special-margin">
        <b-field
          label="Código SAT a buscar (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
        >
          <b-input
            placeholder="Ejemplo: 01010101"
            expanded
            icon="qrcode"
            v-model="satArticleSearch"
          />
        </b-field>
      </b-field>
    </div>

    <div v-if="searchType === 0">
      <b-field grouped class="special-margin">
        <b-field
          label="Tipo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
        >
          <b-select
            placeholder="Ejemplo: Producto"
            expanded
            icon="hammer-screwdriver"
            v-model="satArticleType"
          >
            <option value="" disabled>Seleccione un tipo</option>
            <option value="P">Productos</option>
            <option value="S">Servicios</option>
          </b-select>
        </b-field>
        <b-field
          label="División (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
        >
          <b-select
            placeholder="Ejemplo: 10"
            expanded
            icon="slash-forward"
            v-model="satArticleDivision"
          >
            <option value="" disabled>Seleccione una división</option>
            <option
              v-for="singleDivision in satArticlesDivisions"
              :value="singleDivision.CODDIV"
              :key="singleDivision.CODDIV"
            >
              {{ singleDivision.CODDIV }} {{ singleDivision.NOMBRE }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Grupo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
        >
          <b-select
            placeholder="Ejemplo: 1010"
            expanded
            icon="select-group"
            v-model="satArticleGroup"
          >
            <option value="" disabled>Seleccione un grupo</option>
            <option
              v-for="singleGroup in satArticlesGroups"
              :value="singleGroup.CVE_GPO"
              :key="singleGroup.CVE_GPO"
            >
              {{ singleGroup.CVE_GPO }} {{ singleGroup.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Código Sat (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
        >
          <b-select
            placeholder="Ejemplo: 01010101"
            expanded
            icon="qrcode"
            v-model="selectedSatArticle"
          >
            <option value="" disabled>Seleccione un código</option>
            <option
              v-for="singleArticle in satArticles"
              :value="singleArticle"
              :key="singleArticle.CVE_PROSER"
            >
              {{ singleArticle.CVE_PROSER }} {{ singleArticle.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls" v-if="searchType === 1">
      <b-button
        type="is-success"
        @click="sendSelectSatArticle()"
        :disabled="!selectedSatArticle"
        >Seleccionar código SAT</b-button
      >
      <b-button type="is-success" @click="searchSatArticles()">Buscar</b-button>
      <b-button
        type="is-danger"
        @click="deleteSimpleSearchInformation()"
        class="cancel-button"
        >Limpiar información</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Cancelar</b-button>
    </div>

    <div class="add-global-controls" v-if="searchType === 0">
      <b-button
        type="is-success"
        @click="sendSelectSatArticle()"
        :disabled="!selectedSatArticle"
        >Seleccionar código SAT</b-button
      >
      <b-button
        type="is-danger"
        @click="deleteAdvancedSearchInformation()"
        class="cancel-button"
        >Limpiar filtros</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Cancelar</b-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../../event-bus";
// @ is an alias to /src
export default {
  name: "SearchSatArticle",
  components: {},
  data() {
    return {
      searchType: 1,
      searchTypeName: "Búsqueda simple",
      satArticleType: "",
      satArticlesDivisions: [],
      satArticleDivision: "",
      satArticlesGroups: [],
      satArticleGroup: "",
      satArticles: [],
      selectedSatArticle: "",
      satArticleSearch: "",
    };
  },
  mounted() {},
  watch: {
    satArticleType() {
      this.satArticlesDivisions = [];
      this.satArticleDivision = "";
      this.satArticlesGroups = [];
      this.satArticles = [];
      this.selectedSatArticle = "";
      this.satArticlesDivisions = this.$store.getters.SATARTICLESDIVISION.map(
        (singleArticleDivision) => {
          const articleDivisionWithProps = { ...singleArticleDivision };
          return articleDivisionWithProps;
        }
      ).filter((singleArticleDivision) => {
        return singleArticleDivision.PROSER === this.satArticleType;
      });
    },
    async satArticleDivision() {
      if (this.satArticleDivision) {
        this.satArticlesGroups = [];
        this.satArticles = [];
        this.selectedSatArticle = "";
        const response = await this.$store.dispatch("GETSATARTICLESGROUPS", {
          search: this.satArticleDivision,
          typeSearch: 0,
        });
        this.satArticlesGroups = response;
      }
    },
    async satArticleGroup() {
      if (this.satArticleGroup) {
        this.satArticles = [];
        this.selectedSatArticle = "";
        const response = await this.$store.dispatch("GETSATARTICLES", {
          search: this.satArticleGroup,
          typeSearch: 0,
        });
        this.satArticles = response;
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc emit a selected sat article event so the add article component can get the information
     */
    sendSelectSatArticle() {
      EventBus.$emit("selectedSatArticle", this.selectedSatArticle);
      this.closeModal();
    },
    /**
     *
     * @Searches for sat articles in the database methos used only in simple search
     */
    async searchSatArticles() {
      try {
        if (this.satArticleSearch) {
          this.$store.commit("SET_LOADING", true);
          const response = await this.$store.dispatch("GETSATARTICLES", {
            search: this.satArticleSearch,
            typeSearch: 1,
          });

          this.satArticles = response.slice(0, 250);
          if (response.length > 0) {
            this.selectedSatArticle = response[0];
            this.sendSelectSatArticle();
          }

          this.$store.commit("SET_LOADING", false);
        }
      } catch (error) {
        this.$store.commit("SET_LOADING", false);
      }
    },
    changeAdvancedSearch() {
      this.searchType = 0;
      this.searchTypeName = "Búsqueda avanzada";
      this.satArticleType = "";
      this.satArticlesDivisions = [];
      this.satArticleDivision = "";
      this.satArticlesGroups = [];
      this.satArticleGroup = "";
      this.satArticles = [];
      this.selectedSatArticle = "";
      this.satArticleSearch = "";
    },
    changeSimpleSearch() {
      this.searchType = 1;
      this.searchTypeName = "Búsqueda simple";
      this.satArticleType = "";
      this.satArticlesDivisions = [];
      this.satArticleDivision = "";
      this.satArticlesGroups = [];
      this.satArticleGroup = "";
      this.satArticles = [];
      this.selectedSatArticle = "";
      this.satArticleSearch = "";
    },
    deleteSimpleSearchInformation() {
      this.satArticleSearch = "";
      this.satArticles = [];
      this.selectedSatArticle = "";
    },
    deleteAdvancedSearchInformation() {
      this.satArticleType = "";
      this.satArticlesDivisions = [];
      this.satArticleDivision = "";
      this.satArticlesGroups = [];
      this.satArticleGroup = "";
      this.satArticles = [];
      this.selectedSatArticle = "";
      this.satArticleSearch = "";
    },
  },
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
