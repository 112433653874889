<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control special-margin">
        <b-button type="is-primary" @click="openAddService()"
          >Agregar servicio</b-button
        >
      </div>

      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Servicios: <b>{{ services.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="services"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedServices"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="Articles"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_SERVICIO}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      checkbox-position="left"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      default-sort="CLAVE_SERVICIO"
    >
      <template #empty>
        <div class="has-text-centered">No hay servicios</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :visible="column.display"
          :key="column.field"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <p v-if="!checkIfValid(props.row[column.field])">
            {{ "Sin información" }}
          </p>
          <span v-if="column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <p v-if="!column.money && checkIfValid(props.row[column.field])">
            {{ props.row[column.field] }}
          </p>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <AddProfessionalService :serviceInformation="props.row" />
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import AddProfessionalService from "./AddProfessionalService";
import { EventBus } from "../../../event-bus";
import moment from "moment";
export default {
  name: "ProfessionalServicesList",
  components: {
    AddProfessionalService,
  },
  data() {
    return {
      perPage: 50,
      checkedServices: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_SERVICIO",
          label: "Clave de servicio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "COSTO_MIN_VTA",
          label: "Costo de venta",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "lastSaleFormat",
          label: "Ultima venta",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateSale,
        },
      ],
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETSERVICES"));

    await Promise.all(allPromises);
  },
  mounted() {},
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETSERVICES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    confirmDeleteArticles() {
      if (this.checkedServices.length > 0) {
        this.reloadInformation();
        this.$buefy.dialog.confirm({
          title: "Borrar proveedores",
          message:
            "¿Estas seguro que deseas <b>borrar</b> los proovedores seleccinados? Esta acción no puede ser cambiada.",
          confirmText: "Borrar proveedores",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () =>
            this.$store
              .dispatch("DELETEPROVIDERS", this.checkedServices)
              .then((response) => {
                if (response == "Success") {
                  this.$buefy.dialog.confirm({
                    title: "Éxito",
                    message:
                      "Se borraron exitosamente los proveedores seleccionadas",
                    confirmText: "Entendido",
                    type: "is-success",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                } else {
                  this.$buefy.dialog.confirm({
                    title: "Error",
                    message:
                      "Hubo un error al borrar los proveedores en la base de datos",
                    confirmText: "Entendido",
                    type: "is-danger",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                }
              }),
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay proveedores seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          onConfirm: () => {},
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openAddService() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProfessionalService,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    sortByDateSale(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FE_ULTVTA).getTime() || -Infinity) -
          (new Date(a.FE_ULTVTA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FE_ULTVTA).getTime() || -Infinity) -
          (new Date(b.FE_ULTVTA).getTime() || -Infinity)
        );
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    services() {
      return this.$store.getters.SERVICES.map((singleService) => {
        let singleServiceWithProps = { ...singleService };

        if (singleService.FE_ULTVTA) {
          singleServiceWithProps.lastSaleFormat = moment(
            singleService.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }

        return singleServiceWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
