<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!serviceInformation">
      Agregar Servicio
    </h1>

    <div class="control add-global-controls" v-if="!serviceInformation"></div>

    <div v-if="!serviceInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave de servicio (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': serviceRequiredErrors.CLAVE_SERVICIO }"
        :message="{
          'La clave del servicio no es valida':
            serviceRequiredErrors.CLAVE_SERVICIO,
        }"
      >
        <b-input
          :disabled="serviceInformation"
          placeholder="Ejemplo: 1234f"
          expanded
          icon="pencil"
          v-model="newService.CLAVE_SERVICIO"
          required
        />
      </b-field>
      <b-field label="Descripción (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Martillo azul con mango de madera"
          expanded
          icon="pencil-box"
          v-model="newService.DESCRIPCION"
        />
      </b-field>
    </b-field>

    <b-field
      label="Clave para clasificación interna (Opcional)"
      expanded
      label-position="inside"
      class="special-margin"
    >
      <b-input
        placeholder="Ejemplo: PS1-2"
        expanded
        icon="book-alphabet"
        v-model="newService.CLAVE_CLAS"
      />
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="URL (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: https://url.com"
          expanded
          icon="search-web"
          v-model="newService.URL"
        />
      </b-field>
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Martillo con mango azul"
          expanded
          icon="magnify"
          v-model="newService.OBSERVACIONES"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Código SAT (Requerido)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: 01010101"
          expanded
          icon="qrcode"
          v-model="newService.CLAVE_SAT_DESC"
          required
          @focus="searchSatArticle()"
        />
      </b-field>
    </b-field>

    <h3 class="form-division">Unidades de medida</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Unidad de medida del artículo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': serviceRequiredErrors.UNIDAD_EMP }"
        :message="{
          'La unidad de médida no es valida': serviceRequiredErrors.UNIDAD_EMP,
        }"
      >
        <b-select
          placeholder="Ejemplo: Kilogramo"
          icon="ruler-square-compass"
          expanded
          v-model="newService.UNIDAD_EMP"
          class="required-input"
        >
          <option :value="null" disabled>Selecciones una medida</option>
          <option
            v-for="option in measures"
            :value="option._id"
            :key="option.CLAVE_MED"
          >
            {{ option.DESCRIP_MED }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <h3 class="form-division">Impuestos e indirectos</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Costo de venta (Requerido)"
        expanded
        label-position="inside"
        v-if="
          (companyPaymentPlanModules &&
            companyPaymentPlanModules.purchase.getArticleCostInformation ===
              'S' &&
            permissions &&
            permissions.purchase.getArticleCostInformation === 'S') ||
          !serviceInformation
        "
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 390"
          icon="tag-outline"
          v-model="newService.COSTO_MIN_VTA"
          :required="true"
          :disabled="false"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tipo de IVA (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        required
        :type="{ 'is-danger': serviceRequiredErrors.TIPO_IVA }"
        :message="{
          'El tipo de IVA del articulo no es valida ':
            serviceRequiredErrors.TIPO_IVA,
        }"
      >
        <b-select
          placeholder="Ejemplo: Producto"
          expanded
          v-model="newService.TIPO_IVA"
          icon="bank"
        >
          <option value="NO">Tasa 16%</option>
          <option value="TC">Tasa 0%</option>
          <option value="EX">Exento</option>
          <option value="TA">Otra Tasa (%)</option>
        </b-select>
      </b-field>
      <b-field
        label="Cantidad (Requerido)"
        expanded
        label-position="inside"
        v-if="newService.TIPO_IVA !== 'EX'"
        :type="{ 'is-danger': serviceRequiredErrors.CANT_IVA }"
        :message="{
          'La cantidad de IVA del articulo no es valida':
            serviceRequiredErrors.CANT_IVA,
        }"
      >
        <PercentageInput
          placeholder="Ejemplo: 16"
          expanded
          type="number"
          step="any"
          icon="percent-outline"
          v-model="newService.CANT_IVA"
          required
          :disabled="disableIva"
        />
      </b-field>
    </b-field>

    <b-checkbox
      v-model="newService.RETIENE_ISR"
      class="special-margin margin-right"
      >Retiene ISR a personas morales
    </b-checkbox>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de retención de ISR (Requerido)"
        expanded
        label-position="inside"
        v-if="newService.RETIENE_ISR"
      >
        <PercentageInput
          placeholder="Ejemplo: 10"
          expanded
          type="number"
          step="any"
          icon="percent-outline"
          v-model="newService.CANT_RETENCION_ISR"
          required
        />
      </b-field>
    </b-field>

    <b-checkbox
      v-model="newService.RETIENE_IVA"
      class="special-margin margin-right"
      >Retiene IVA a personas morales
    </b-checkbox>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de retención de IVA (Requerido)"
        expanded
        label-position="inside"
        v-if="newService.RETIENE_IVA"
      >
        <PercentageInput
          placeholder="Ejemplo: 10"
          expanded
          type="number"
          step="any"
          icon="percent-outline"
          v-model="newService.CANT_RETENCION_IVA"
          required
        />
      </b-field>
    </b-field>

    <h3 class="form-division">Información de Venta</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de última venta (Opcional)"
        expanded
        label-position="inside"
      >
        <b-datepicker
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          v-model="newService.FE_ULTVTA"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
        />
      </b-field>
    </b-field>

    <b-checkbox
      v-model="newService.ACTIVO_VENTA"
      class="special-margin margin-right"
      >Disponible para ventas
    </b-checkbox>

    <b-checkbox
      v-model="newService.INACTIVO_UTILIDAD"
      class="special-margin margin-right"
      >No usar en cálculo de utilidad promedio en analíticos
    </b-checkbox>

    <div class="add-global-controls" v-if="serviceInformation">
      <b-button type="is-success" @click="saveService(1)">
        Actualizar servicio
      </b-button>
    </div>

    <div class="add-global-controls" v-if="!serviceInformation">
      <b-button type="is-success" @click="saveService(0)">
        Crear servicio
      </b-button>
      <b-button type="is-danger" class="cancel-button" @click="closeModal()"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import SearchSatArticle from "../../Purchases/components/sat-articles/SearchSatArticle";
import moment from "moment";
import { EventBus } from "../../../event-bus";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
import {
  regimenPersonaFisica,
  regimenResico,
} from "../../../utils/env-variables-fns";

// @ is an alias to /src
export default {
  name: "AddProfessionalService",
  props: ["serviceInformation"],
  components: {},
  data() {
    return {
      moment: moment,
      newService: {
        STATUS: "AC",
        ACTIVO_VENTA: true,
        INACTIVO_UTILIDAD: false,
        CLAVE_CLAS: "",
        CLAVE_SERVICIO: "",
        DESCRIPCION: "",
        UNIDAD_EMP: null,
        COSTO_MIN_VTA: 0,
        TIPO_IVA: "NO",
        CANT_IVA: 16,
        TIPO_IEPS: "NA",
        CANT_IEPS: 0,
        URL: "",
        OBSERVACIONES: "",
        CLAVE_SAT: "623d595ce7b4ec90b98f7588",
        CLAVE_SAT_DESC: "01010101 NO EXISTE EN EL CATÁLOGO",
        FE_ULTVTA: undefined,
        RETIENE_ISR: false,
        CANT_RETENCION_ISR: 0,
        RETIENE_IVA: false,
        CANT_RETENCION_IVA: 0,
      },
      serviceRequiredErrors: {
        STATUS: false,
        CLAVE_SERVICIO: false,
        COSTO_MIN_VTA: false,
        TIPO_IVA: false,
        CANT_IVA: false,
        TIPO_IEPS: false,
        UNIDAD_EMP: false,
        CLAVE_SAT: false,
      },
      disableIva: true,
      regimenPersonaFisica: regimenPersonaFisica,
      regimenResico: regimenResico,
    };
  },
  mounted() {
    // Fill the article information in form fields
    if (this.serviceInformation) {
      // Fill the form with received information
      fillObjectForm(this.serviceInformation, this.newService);
      if (this.newService.FE_ULTVTA) {
        this.newService.FE_ULTVTA = new Date(this.newService.FE_ULTVTA);
      }
      this.newService.TIPO_IVA = this.newService.CANT_IVA !== 0 ? "NO" : "TC";
      this.newService.TIPO_IEPS = this.newService.CANT_IEPS !== 0 ? "TA" : "NA";
      if (this.newService.TIPO_IVA === "EX") this.newService.disableIva = true;

      if (this.serviceInformation.UNIDAD_EMP)
        this.newService.UNIDAD_EMP = this.serviceInformation.UNIDAD_EMP._id;
      this.newService._id = this.serviceInformation._id;
    }

    if (!this.serviceInformation) {
      if (this.companyInformation.CLAVE_REGIMEN === this.regimenPersonaFisica) {
        this.newService.RETIENE_ISR = true;
        this.newService.CANT_RETENCION_ISR = 10;
        this.newService.RETIENE_IVA = true;
        this.newService.CANT_RETENCION_IVA = 10.6667;
      }

      if (this.companyInformation.CLAVE_REGIMEN === this.regimenResico) {
        this.newService.RETIENE_ISR = true;
        this.newService.CANT_RETENCION_ISR = 10;
        this.newService.RETIENE_IVA = true;
        this.newService.CANT_RETENCION_IVA = 1.25;
      }
    }

    EventBus.$on("selectedSatArticle", (selectedSatArticle) => {
      selectedSatArticle;
      this.newService.CLAVE_SAT_DESC = `${selectedSatArticle.CVE_PROSER} ${selectedSatArticle.DESCRIPCION}`;
      this.CLAVE_SAT = selectedSatArticle._id;
      this.newService.CLAVE_SAT = selectedSatArticle._id;
    });
  },
  async created() {
    let allPromises = [];
    await Promise.all(allPromises);
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    searchSatArticle() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SearchSatArticle,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc saves or updates an aticle depending on the type
     */
    async saveService(type) {
      try {
        this.serviceRequiredErrors = validateFields(
          this.newService,
          this.serviceRequiredErrors
        );
        if (checkValidationErrors(this.serviceRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVESERVICE" : "EDITSERVICE",
            this.newService
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el servicio",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el servicio en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el servicio en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el servicio en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    TIPO_IVA(newValue) {
      if (newValue === "NO") {
        this.newService.CANT_IVA = 16;
        this.disableIva = true;
      }

      if (newValue === "TC") {
        this.newService.CANT_IVA = 0;
        this.disableIva = true;
      }

      if (newValue === "EX") {
        this.newService.CANT_IVA = 0;
        this.disableIva = true;
      }

      if (newValue === "TA") {
        this.disableIva = false;
      }
    },
  },
  computed: {
    TIPO_IVA() {
      return this.newService.TIPO_IVA;
    },
    brands() {
      return this.$store.getters.BRANDS;
    },
    measures() {
      return this.$store.getters.MEASURES;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
